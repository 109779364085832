import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import SEO from '../components/SEO';
import RMAPolicies from '../components/RMAPolicies';
import { VscChevronRight } from 'react-icons/vsc';
import { FaWindowClose, FaPlus, FaCheck } from 'react-icons/fa';
import { DevTool } from '@hookform/devtools';
import PulseLoader from 'react-spinners/PulseLoader';
import { products } from '../util/allProducts';
import { useFieldArray, useForm } from 'react-hook-form';
import { sendFormToRmaApp } from '../requests/sendFormToRmaApp';
import { sendFormToCRM } from '../requests/sendFormToCRM';
import logo from '../images/logos/logo-bglight-colors.svg';

type ReturnItem = {
  quantity: string;
  reasonForReturn: string;
  product: string;
};

export type RmaFormData = {
  name: string;
  email: string;
  phone: string;
  company: string;
  order: string;
  comments: string;
  items: ReturnItem[];
};

type LoadingStatus = null | 'loading' | 'error' | 'complete';

type ReturnsProps = {
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
};

const Returns: React.FC<ReturnsProps> = () => {
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const [status, setStatus] = useState<LoadingStatus>(null);

  const { register, handleSubmit, errors, control } = useForm<RmaFormData>({
    mode: 'onBlur',
    defaultValues: {
      items: [{ product: '', quantity: '', reasonForReturn: '' }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const onSubmit = handleSubmit(async (data) => {
    const isTest =
      data.company.toLowerCase().includes('test') ||
      data.name.toLowerCase().includes('test');
    setStatus('loading');

    try {
      // Send the everything to the CRM server for now
      if (!isTest) {
        const json = await sendFormToRmaApp(data);

        await sendFormToCRM(data, json.data.rma_number);
        navigate('/rmas/' + json.data.id);
        return;
      }
      await sendFormToCRM(data);
      setStatus('complete');
    } catch (err) {
      console.log(err);
      setStatus('error');
    }
  });

  return (
    <>
      <SEO title={`ManaMed Returns`} />
      {showPolicyModal && <RMAPolicies setShowModal={setShowPolicyModal} />}
      {status === 'error' && (
        <ErrorModalContainer onClick={() => setStatus(null)}>
          <ErrorModal>
            There was an error with this request. Please reach out to
            support@manamed.com.
          </ErrorModal>
        </ErrorModalContainer>
      )}
      <Container>
        <Logo>
          <img src={logo} />
        </Logo>
        <Title>Submit a Return/RMA</Title>
        <Disclaimer>
          <h2>
            IMPORTANT: ManaMed will not accept any returns that do not have a
            valid RMA number. Please submit your information here and we will
            review and respond with the RMA number and a link to download the
            packing slip to include in the box.
          </h2>
          <button onClick={() => setShowPolicyModal(true)}>
            <span>View full returns policy</span> <VscChevronRight />
          </button>
        </Disclaimer>
        <form onSubmit={onSubmit}>
          <FormFields>
            <div>
              <fieldset>
                <label>Name</label>
                <input
                  name="name"
                  type="text"
                  ref={register({ required: true })}
                />
                {errors.name && (
                  <ErrorMessage>Please enter a name.</ErrorMessage>
                )}
              </fieldset>
              <fieldset>
                <label>Company</label>
                <input
                  name="company"
                  type="text"
                  ref={register({ required: true })}
                />
                {errors.company && (
                  <ErrorMessage>Please enter a company.</ErrorMessage>
                )}
              </fieldset>
              <fieldset>
                <label>Email</label>
                <input
                  name="email"
                  type="text"
                  ref={register({ required: true })}
                />
                {errors.email && (
                  <ErrorMessage>Please enter a valid email.</ErrorMessage>
                )}
              </fieldset>
              <fieldset>
                <label>Phone</label>
                <input
                  name="phone"
                  type="number"
                  ref={register({
                    required: true,
                    minLength: 10,
                    maxLength: 10,
                  })}
                />
                {errors.phone && (
                  <ErrorMessage>
                    Please enter a 10 digit phone number.
                  </ErrorMessage>
                )}
              </fieldset>
              <fieldset>
                <label>Order Number(s)</label>
                <input
                  name="order"
                  type="text"
                  ref={register({ required: true })}
                />
                {errors.order && (
                  <ErrorMessage>Please enter an order number.</ErrorMessage>
                )}
              </fieldset>

              <fieldset>
                <label>Explanation for Return</label>
                <textarea
                  name="comments"
                  rows={5}
                  ref={register({ required: true })}
                />
              </fieldset>
            </div>
            <div>
              <InlineFields>
                <label>Product</label>
                <label>Qty</label>
                <label>Reason</label>
              </InlineFields>
              <ProductRows>
                {fields.map(({ id }, index) => (
                  <InlineFields key={id}>
                    <input
                      list="allProducts"
                      ref={register({ required: true })}
                      name={`items[${index}].product`}
                    />
                    <input
                      type="number"
                      min="1"
                      name={`items[${index}].quantity`}
                      ref={register({ required: true, max: 9999 })}
                    />
                    <select
                      name={`items[${index}].reasonForReturn`}
                      ref={register({ required: true })}
                      defaultValue=""
                    >
                      <option disabled></option>
                      <option>Other (Provide Explanation)</option>
                      <option>Defective</option>
                      <option>No Longer Needed</option>
                      <option>Wrong Item Sent</option>
                      <option>Wrong Size</option>
                    </select>
                    <button type="button" onClick={() => remove(index)}>
                      <FaWindowClose />
                    </button>
                  </InlineFields>
                ))}
              </ProductRows>
              {errors.items && (
                <ErrorMessage>
                  All product fields must be filled out.
                </ErrorMessage>
              )}
              <AddButton type="button" onClick={() => append({})}>
                <FaPlus />
                Add Product
              </AddButton>
            </div>
          </FormFields>

          <Submit type="submit" disabled={status === 'loading'}>
            {status === 'loading' ? (
              <PulseLoader size="10px" color={'white'} />
            ) : (
              'Submit'
            )}
          </Submit>

          <datalist id="allProducts">
            {products.map((product, index) => (
              <option key={product + index} value={product} />
            ))}
          </datalist>
        </form>
        <DevTool control={control} /> {/* set up the dev tool */}
      </Container>
    </>
  );
};

export default Returns;

const Logo = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  img {
    width: 200px;
  }
`;

const Container = styled.div`
  min-height: 80vh;
  padding: 3rem 1rem 4rem;
  max-width: 1250px;
  margin: auto;

  @media (max-width: 1024px) {
    padding: 3rem 1rem;
  }
`;

const Disclaimer = styled.div`
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  max-width: 80ch;
  text-align: center;
  margin-inline: auto;

  button {
    display: inline-block;
    color: #2e8b05;
    font-size: 16px;
    cursor: pointer;
    font-weight: 600;

    svg {
      margin-bottom: 2px;
      font-size: 20px;
      margin-left: -2px;
      transition: all 0.2s ease;
    }

    :hover {
      svg {
        transform: translateX(4px);
      }
      span {
        box-shadow: 0 2px currentColor;
      }
    }
  }

  h2 {
    font-size: 16px;
    margin: 0.5rem 0 2rem;
    display: inline;
  }
`;

const FormFields = styled.div`
  width: 100%;
  text-align: left;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }

  fieldset {
    margin: 0;
    width: 100%;
    border: none;
    padding: 0;
    margin-bottom: 0.8rem;
  }

  label {
    font-size: 13px;
    color: #545454;
    display: block;
    font-weight: 600;
    margin-bottom: 2px;
  }

  input,
  select {
    height: 40px;
  }

  input,
  select,
  textarea {
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid grey;
    width: 100%;
    font-size: 14px;
  }

  datalist {
    display: none;
  }

  @media (max-width: 1024px) {
    margin-bottom: 1rem;
  }
`;

const Success = styled.div`
  background: white;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;

  svg {
    color: #60bf60;
    font-size: 100px;
    margin-bottom: 20px;
  }

  div {
    max-width: 500px;
    text-align: center;
  }
`;

const InlineFields = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1.5fr 70px 1fr max-content;

  button {
    color: darkgray;
    font-size: 14px;
    padding: 0;
    cursor: pointer;
  }
`;

const AddButton = styled.button`
  color: #2e8b05;
  cursor: pointer;
  padding: 5px 5px;
  margin-left: -5px;
  align-items: center;
  display: flex;
  margin-top: 7px;

  svg {
    margin-right: 5px;
    margin-top: 2px;
  }

  :hover {
    color: darkgreen;
  }
`;

const Title = styled.h3`
  padding: 0;
  margin: 0;
  margin-bottom: 0;
  font-size: 32px;
  text-align: center;
`;

const Submit = styled.button`
  color: white;
  background: #374150;
  padding: 0.5rem 1.5rem;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;

  :hover {
    background: var(--text-color-secondary);
  }
`;

const ErrorMessage = styled.p`
  color: var(--error-color);
  font-size: 12px;
  margin-top: 2px;
`;

const ProductRows = styled.div`
  display: grid;
  gap: 1rem;
`;

const ErrorModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #ffffffe0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorModal = styled.div`
  padding: 4rem;
  font-size: 1.5rem;
  box-shadow: var(--modal-box-shadow);
  background: white;
`;
