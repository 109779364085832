import { RmaFormData } from '../pages/returns';
import { ensureError } from '../util/ensureError';

export async function sendFormToCRM(
  data: RmaFormData,
  rmaNumber?: number
): Promise<{ success: true; json: any } | { success: false; error: string }> {
  const url = process.env.GATSBY_CRM_API_URL as string;
  console.log('sending to ', url);

  console.log('RMA NUMBER:', rmaNumber);

  const rma_number = rmaNumber ? rmaNumber.toString() : '';

  try {
    const requestBody = {
      company_name: data.company,
      account_number: '',
      email: data.email,
      full_name: data.name,
      phone: data.phone,
      explanation: data.comments,
      order_number: data.order,
      rma_number,
      items: data.items.map((item) => ({
        item_number: getItemNumber(item.product),
        item_name: getItemName(item.product),
        quantity: parseInt(item.quantity),
        lot_number: '',
        reason: formatReason(item.reasonForReturn),
      })),
    };

    console.log('Send to RMA data: ', requestBody);

    const res = await fetch(
      url + '/api/v1/customer-form-submit/create-web-form',
      {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const json = await res.json();
    console.log(json);

    if (!res.ok) {
      console.error(json);
      throw new Error(json.error);
    }

    return { success: true, json };
  } catch (err) {
    console.error(err);
    const error = ensureError(err);
    return { success: false, error: error.message };
  }
}

function formatReason(reason: string) {
  switch (reason.toLowerCase()) {
    case 'defective':
      return 'defective';
    case 'wrong item sent':
      return 'wrong_item_sent';
    case 'no longer needed':
      return 'not_needed';
    case 'wrong size':
      return 'wrong_size';
    default:
      return 'other';
  }
}

function getItemNumber(text: string) {
  return text.split(' - ')[0];
}

function getItemName(text: string) {
  const splits = text.split(' - ');
  return splits.slice(1).join(' - ');
}
