import React, { useRef, MouseEvent, useState } from 'react';
import styled from 'styled-components';
import { useForm, useFieldArray } from 'react-hook-form';
import { FaChevronRight } from 'react-icons/fa';
import { VscClose } from 'react-icons/vsc';

type RMAPoliciesProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};
const RMAPolicies: React.FC<RMAPoliciesProps> = ({ setShowModal }) => {
  return (
    <FullPageContainer>
      <Modal>
        <Close onClick={() => setShowModal(false)}>
          <VscClose />
        </Close>
        <TextContainer>
          <Section>
            <Title>Returns</Title>
            <Ol>
              <li>
                Return authorization is required for all returned merchandise –
                RMA requests may be submitted on the ManaMed Website or by
                contacting support@manamed.com. Note: Only direct ManaMed
                customers with a valid order number may request an RMA.
              </li>
              <li>
                All returns must be shipped prepaid by the customer and the
                outside of the package must be clearly marked with the Return
                Material Authorization (RMA) number. Any product returned to
                ManaMed without a valid RMA number will be returned at the
                customer’s expense. Freight collect returns will be refused.
              </li>
              <li>
                Products which are not defective may be returned to ManaMed
                within 90 days of purchase for a ManaMed order credit.
                Authorized unused item returns are subject to a restocking fee
                equal to 20 percent of the purchase price. The ManaMed order
                credit may only be used for future purchase of ManaMed products.
              </li>
              <li>
                To be eligible for return, the product must be in original
                unopened packaging and in resalable condition. If received in
                other than original unopened packaging credit shall be denied.
                Discontinued, altered or logoed products are not eligible for
                return.
              </li>
            </Ol>
          </Section>
          <Section>
            <Title>Warranty Returns</Title>
            <Ol>
              <li>
                Items may be returned for warranty consideration by requesting
                an RMA via the ManaMed Website or by contacting
                support@manamed.com. Note: Only direct ManaMed customers with a
                valid order number may request a warranty return.
              </li>
              <li>
                All products being returned for warranty consideration must be
                shipped freight prepaid by the customer in their original
                container or equivalent and be clearly marked with the RMA
                number on the outside of the box. Items returned without valid
                RMA markings will be returned at the customer’s expense. ManaMed
                will not be responsible for any loss or damage incurred in
                shipping.
              </li>
              <li>
                ManaMed will carefully inspect, test, and evaluate the product
                once received. If found to be defective, the item will be
                repaired, replaced at no cost to the customer or a credit will
                be provided to the customer’s account at ManaMed’s discretion.
              </li>
              <li>
                This limited warranty does not apply if the defective product:
                <ol type="a">
                  <li>
                    was subject to abuse, neglect, misuse, or accidental damage.
                  </li>
                  <li>
                    has not been used in accordance with ManaMed’s written
                    instructions for use (IFU).
                  </li>
                  <li>
                    was modified from its original configuration or repaired or
                    altered by anyone other than ManaMed or authorized ManaMed
                    Representative.
                  </li>
                  <li>
                    was not purchased from ManaMed or an authorized dealer of
                    ManaMed.
                  </li>
                </ol>
              </li>
            </Ol>
          </Section>
          <Section>
            <Title>Goods Damaged in Transit</Title>
            <Ol>
              <li>
                ManaMed ships all items FOB Origin. This means that the title
                for the item transfers to the buyer once the courier picks up
                the package. If there is damage, a claim must be filed with the
                courier by the owner of the good, which is the buyer. Shipping
                damage is not covered by the warranty. Shipping firms do have
                legal obligations as to when and how much to compensate for the
                damage, but only if the claim is filed on time and in the
                correct manner.
              </li>
              <li>
                ManaMed cannot be responsible for any failure to deliver or
                delay in delivery resulting from acts of God, strikes, lockouts,
                fires, inability to obtain materials or shipping space, delays
                of carriers, or suppliers, governmental acts or regulations or
                other cause beyond our direct control.
              </li>
              <li>
                Any loss, damage, short shipment and / or shipping error claims
                must be reported to ManaMed Support (support@manamed.com) within
                48 hours of receipt of merchandise.
              </li>
            </Ol>
          </Section>
        </TextContainer>
      </Modal>
    </FullPageContainer>
  );
};

export default RMAPolicies;

const FullPageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 16;
  background: var(--modal-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Modal = styled.div`
  width: 100%;
  max-width: 800px;
  position: relative;
  background: white;
  box-shadow: var(--modal-box-shadow);
  padding: 2.5rem 2rem;
  height: 90vh;
  overflow-y: auto;

  @media (max-width: 100px) {
    height: 100vh;
  }
`;

const TextContainer = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Section = styled.section`
  @media (max-width: 1024px) {
    font-size: 13px;
  }

  a {
    text-decoration: underline;
  }
`;

const P = styled.p`
  margin: 0;
  margin-bottom: 1rem;
  font-size: 13px;
`;

const Ol = styled.ol`
  margin: 0;

  li {
    padding-left: 5px;
    margin: 0.2rem 0;
    font-size: 13px;
  }
`;

const Title = styled.h2`
  font-weight: var(--font-weight-bold);
  font-size: 18px;
  margin-bottom: 1rem;
  text-align: left;

  @media (max-width: 1024px) {
    font-size: 15px;
  }
`;

const SubmitButton = styled.button`
  color: var(--primary-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;

  svg {
    margin-left: 4px;
    font-size: 12px;
  }
  :hover {
    text-decoration: underline;
  }

  @media (min-width: 1300px) {
    font-size: 18px;
    margin-left: -6px;
  }
`;

const ActionSection = styled.div`
  margin-bottom: 20px;

  h1 {
    font-size: 20px;
    margin: 0;
    margin-bottom: 10px;
  }
`;

const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
`;
