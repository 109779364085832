export const products = [
  'MGO71L - ManaGo 71 Large',
  'MGO71XL - ManaGo 71 XLarge',
  'MGO71M - ManaGo 71 Medium',
  'MGO71S - ManaGo 71 Small',
  'CONS-KB011-01 - 3-in-1 Postpartum Recovery Support Belt',
  'AK001 - Ankle Kit',
  'BAGANKLE - Ankle Kit Bag',
  'BB001 - Belly Band Black',
  'BS001 - Bionic Sling',
  'CONS-COMP-SOCK - BLITZU Compression Socks',
  'CE001L - Cabo Elbow Left',
  'CE001R - Cabo Elbow Right',
  'TRBL45L2 - Cabo Knee Brace Large Left',
  'TRBL45L1 - Cabo Knee Brace Large Right',
  'TRBL45M2 - Cabo Knee Brace Medium Left',
  'TRBL45M1 - Cabo Knee Brace Medium Right',
  'TRBL45S2 - Cabo Knee Brace Small Left',
  'TRBL45S1 - Cabo Knee Brace Small Right',
  'TRBL45XL2 - Cabo Knee Brace XL Left',
  'TRBL45XL1 - Cabo Knee Brace XL Right',
  'TRBL45XXL2 - Cabo Knee Brace XXL Left',
  'TRBL45XXL1 - Cabo Knee Brace XXL Right',
  'CK001 - Cervical Kit',
  'BAGCERV - Cervical Kit Bag',
  'PFCHG - Charger for PlasmaFlow',
  'EZROMEXT - EZ ROM Ice Extension Strap',
  'EZR001-IP - EZ Rom Ice Pack',
  'OFF001L - EZOA Left',
  'OFF001R - EZOA Right',
  'GOATL - GOAT Left',
  'GOATR - GOAT Right',
  'HB050 - Halfback 50',
  'HB050-2 - Halfback2-50',
  'HK001 - Hip Kit',
  'BAGHIP - Hip Kit Bag',
  'PFHCHG - Hospital Charger for PlasmaFlow',
  'PFHCHG-12.5 - Hospital Charger for PlasmaFlow',
  'KB001 - Kahuna Brace',
  'KB001-P - Kahuna Brace PIllow',
  'KSS001 - Kangaroo Shoulder Sling',
  'KSS001-P - Kangaroo Sling Pillow',
  'KS2397 - L2397 Knee Sleeve',
  'WB3916 - L3916 Wrist Brace',
  'M-32 - Lasso Disposable Sleeve Insert White',
  'LIDOOTC6 - Lidocaine 4% pain releiving gel patch / 6 patches',
  'MACAIR01-S-L - MAC Air Customizable Walker Boot Short Large',
  'MACAIR01-S-M - MAC Air Customizable Walker Boot Short Medium',
  'MACAIR01-S-S - MAC Air Customizable Walker Boot Short Small',
  'MACAIR01-S-XL - MAC Air Customizable Walker Boot Short XLarge',
  'MACAIR01-S-XS - MAC Air Customizable Walker Boot Short XSmall',
  'MACAIR01-L - MAC Air Customizable Walker Boot Tall Large',
  'MACAIR01-M - MAC Air Customizable Walker Boot Tall Medium',
  'MACAIR01-SAM - MAC Air Customizable Walker Boot Tall Sample',
  'MACAIR01-S - MAC Air Customizable Walker Boot Tall Small',
  'MACAIR01-XL - MAC Air Customizable Walker Boot Tall XLarge',
  'MACAIR01-XS - MAC Air Customizable Walker Boot Tall XSmall',
  'MACTOE01 - MAC Soft Toe Cover Accessory',
  'MP001 - Mana Pain Patch',
  'MCOLDPAD-ANKLE - ManaCold Platinum Ankle Pad',
  'MCOLDPAD-BACK - ManaCold Platinum Back Pad',
  'MCOLDPAD-HIP - ManaCold Platinum Hip Pad',
  'MCOLDCHG - ManaCold Platinum Replacement Power Supply',
  'MANACOLD-PTSHLD - ManaCold Platinum Shoulder Combo Unit',
  'MCOLDPAD-SHLD - ManaCold Platinum Shoulder Pad',
  'MCOLDPAD - ManaCold Platinum Universal Pad',
  'MANACOLD01-PT - ManaCold Platinum Universial Combo Unit',
  'MANACOLD01 - ManaCold Therapy Unit',
  'EZ3PIMM01-18 - ManaEZ 3P Immobilizer 18"',
  'EZ3PIMM01-20 - ManaEZ 3P Immobilizer 20"',
  'EZ3PIMM01-24 - ManaEZ 3P Immobilizer 24"',
  'EZANKLE02L - ManaEZ Ankle 02 Large',
  'EZANKLE02M - ManaEZ Ankle 02 Medium',
  'EZANKLE02S - ManaEZ Ankle 02 Small',
  'EZANKLE02XL - ManaEZ Ankle 02 XLarge',
  'EZANKLE02XS - ManaEZ Ankle 02 XS',
  'EZANKLE02XXL - ManaEZ Ankle 02 XXLarge',
  'EZANKLE06L - ManaEZ Ankle 06 Large',
  'EZANKLE06M - ManaEZ Ankle 06 Medium',
  'EZANKLE06S - ManaEZ Ankle 06 Small',
  'EZANKLE06XL - ManaEZ Ankle 06 XLarge',
  'EZANKLE06XS - ManaEZ Ankle 06 XSmall',
  'EZANKLE06XXL - ManaEZ Ankle 06 XXLarge',
  'EZA71LL - ManaEZ Ankle 71 Left Large',
  'EZA71LM - ManaEZ Ankle 71 Left Medium',
  'EZA71LS - ManaEZ Ankle 71 Left Small',
  'EZA71RL - ManaEZ Ankle 71 Right Large',
  'EZA71RM - ManaEZ Ankle 71 Right Medium',
  'EZA71RS - ManaEZ Ankle 71 Right Small',
  'EZANKLE01 - ManaEZ Ankle Ice',
  'EZAS4350 - ManaEZ Ankle Stirrup Universal',
  'EZBA01L - ManaEZ Boot Air Large',
  'EZBA01M - ManaEZ Boot Air Medium',
  'SEZBA01L - ManaEZ Boot Air Short Large',
  'SEZBA01M - ManaEZ Boot Air Short Medium',
  'SEZBA01S - ManaEZ Boot Air Short Small',
  'SEZBA01XL - ManaEZ Boot Air Short XL',
  'SEZBA01XS - ManaEZ Boot Air Short XS',
  'EZBA01S - ManaEZ Boot Air Small',
  'EZBA01XL - ManaEZ Boot Air XL',
  'EZBA01XS - ManaEZ Boot Air XS',
  'EZBA01XXL - ManaEZ Boot Air XXL',
  'EZBPEDL - ManaEZ Boot Pediatric - Large',
  'EZBPEDM - ManaEZ Boot Pediatric - Medium',
  'EZBPEDS - ManaEZ Boot Pediatric - Small',
  'EZC074 - ManaEZ Collar 74',
  'EZC080 - ManaEZ Collar 80',
  'EZCRUTCH01 - ManaEZ Crutch Black',
  'EZHICE01 - ManaEZ Hinged Ice Brace',
  'MANAEZ20ICE - ManaEZ Hinged Ice No Compression',
  'EZHK1820L - ManaEZ Hinged Knee Brace Large',
  'EZHK1820M - ManaEZ Hinged Knee Brace Medium',
  'EZHK1820S - ManaEZ Hinged Knee Brace Small',
  'EZHK1820XL - ManaEZ Hinged Knee Brace XL',
  'EZHK1820XS - ManaEZ Hinged Knee Brace XS',
  'EZHK1820XXL - ManaEZ Hinged Knee Brace XXL',
  'EZHK1820XXXL - ManaEZ Hinged Knee Brace XXXL',
  'EZHIP01B - ManaEZ Hip Ice',
  'EZIWH01-IP - ManaEZ Hip Wrap Ice Pack',
  'EZH3980 - ManaEZ Humerus Universal',
  'EZIWH01 - ManaEZ Ice Wrap Hip',
  'EZIWHIPEXT - ManaEZ Ice Wrap Hip Extension Strap',
  'EZIWKN01 - ManaEZ Ice Wrap Knee',
  'EZIWRIST01 - ManaEZ Ice Wrist Brace',
  'EZIMM01-18 - ManaEZ Immobilizer 18"',
  'EZIMM01-20 - ManaEZ Immobilizer 20"',
  'EZIMM01-24 - ManaEZ Immobilizer 24"',
  'EZRE001 - ManaEZ ROM Elite',
  'EZRS1833 - ManaEZ ROM Elite Short',
  'EZR001 - ManaEZ ROM Ice',
  'EZR001-XSM - ManaEZ ROM Ice Small',
  'EZSHLD - ManaEZ Shoulder Ice',
  'EZSL01L - ManaEZ Sling Large',
  'EZSL01M - ManaEZ Sling Medium',
  'EZSL01S - ManaEZ Sling Small',
  'EZSLUNI - ManaEZ Sling Universal',
  'EZSL01XL - ManaEZ Sling XL',
  'EZSL01XXL - ManaEZ Sling XXL',
  'EZ120 - ManaEZ Soft Collar Universal',
  'EZSPRING01 - ManaEZ Spring Crutch',
  'EZSUS01 - ManaEZ Suspension Sleeve',
  'EZTS01 - ManaEZ Tendon Strap Universal',
  'EZTS3809L - ManaEZ Thumb Spica Left',
  'EZTS3809R - ManaEZ Thumb Spica Right',
  'EZW33 - ManaEZ Wrap 33',
  'EZW3908L - ManaEZ Wrist Left',
  'EZW3908R - ManaEZ Wrist Right',
  'MF002-RX - ManaFlexx 2 NMES',
  'MFLOW52 - ManaFlow (Calibrated)',
  'MFLOW51-A - ManaFlow (Non-Calibrated)',
  'MFLOW51 - ManaFlow (Non-Calibrated)',
  'MFLOWLEGEXT - ManaFlow 52 Full leg Cuff Extension',
  'MFLOWEXT - ManaFlow Extesion Sleeve',
  'MFLOW51LEG - ManaFlow51 Full Leg Cuff',
  'MFLOW52LEG - ManaFlow52 Full Leg Cuff',
  'BAGSPINE - ManaMed Black Bag',
  'BAGTJK - ManaMed Red Bag',
  'MANAGEL01 - ManaMed Ultrasound Coupling Gel',
  'MSUSCHG - ManaMed Ultrasound Replacement Charger',
  'RED01 - ManaRed Infrared Forehead Thermometer',
  'MSLIDE01 - ManaSlide Blue Transfer Blanket',
  'MSPORT02 - ManaSport+ Ultrasonic Diathermy Device',
  'MWSLING01 - ManaWater Sling',
  'MKB001 - Mini Kahuna Brace',
  'MKB001-HO - Mini Kahuna Brace with Sticker',
  'BAGTBS - Mommy Care Kit Bag',
  "NB-603 - Nature's Bond Breast Pump",
  'PFLIGHT01 - PlasmaFlight DVT',
  'PF0001 - PlasmaFlow DVT',
  'PF0001-MX - PlasmaFlow DVT',
  'PFEXT - PlasmaFlow Extension Sleeves',
  'PWADAP - Plasmawave Adapter',
  'M-40 - PlasmaWave Calf Sleeves Standard',
  'M-42 - PlasmaWave Calf Sleeves XL',
  'M-20 - PlasmaWave Foot Garment Small',
  'M-21 - PlasmaWave Foot Garment Standard',
  'PWAVE01 - PlasmaWave Portable DVT',
  'PWRACK - PlasmaWave Rack with Clamp',
  'M-51 - PlasmaWave Thigh Garment Small',
  'M-50 - PlasmaWave Thigh Garment Standard',
  'PW15 - PlasmaWave Tube Set',
  'MMJB001A - Reddy Joint Bath',
  'MFPAD - Replacement Pad for ManaFlexx',
  'RB001L - Royal Boot Air Large',
  'SRB001L - Royal Boot Air Large Short',
  'RB001M - Royal Boot Air Medium',
  'SRB001M - Royal Boot Air Medium Short',
  'RB001S - Royal Boot Air Small',
  'SRB001S - Royal Boot Air Small Short',
  'RB001XL - Royal Boot Air XL',
  'SRB001XL - Royal Boot Air XL Short',
  'RB001XS - Royal Boot Air XS',
  'SRB001XS - Royal Boot Air XS Short',
  'OSAK3 - Shoulder Brace with Airplane Kit',
  'SHK001 - Shoulder Kit',
  'BAGSHLD - Shoulder Kit Bag',
  'SK001 - Spine Kit',
  'TB027-2 - Tailback 27',
  'TB042 - Tailback 42',
  'TB048 - Tailback 48',
  'TB050 - Tailback 50',
  'TB056 - Tailback 56',
  'TB064 - Tailback 64',
  'TBEXT - Tailback Extension Strap',
  'TB042-2 - Tailback2 42',
  'TB048-2 - Tailback2 48',
  'TB050-2 - Tailback2 50',
  'TB056-2 - Tailback2 56',
  'TB064-2 - Tailback2 64',
  'TB050-P - TB050 Ice Pack ***',
  'M-31 - Theriot Universal Calf Garment',
  'TJK001 - Total Joint Knee Kit',
  'TRBL45L1-L - Trailblazer Knee Brace Large Right - Legacy',
  'TRBL45M2-L - Trailblazer Knee Brace Medium Left - Legacy',
  'TRBL45M1-L - Trailblazer Knee Brace Medium Right - Legacy',
  'TRBL45S2-L - Trailblazer Knee Brace Small Left - Legacy',
  'TRBL45S1-L - Trailblazer Knee Brace Small Right - Legacy',
  'MANAFUSE - Ultrasound Bone Growth Stimulator',
];
