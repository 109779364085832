import { z } from 'zod';
import { RmaFormData } from '../pages/returns';

export async function sendFormToRmaApp(data: RmaFormData) {
  const url = process.env.GATSBY_RMA_API_URL as string;

  const submitBody: any = {
    fullName: data.name,
    company: data.company,
    phone: data.phone,
    email: data.email,
    salesOrder: data.order,
    customerComment: data.comments,
    items: data.items.map((i) => ({
      reasonForReturn: i.reasonForReturn,
      // product: i.product.split(' - ')[1] || i.product,
      productName: i.product.split(' - ')[1] || i.product,
      sku: i.product.split(' - ')[0],
      quantity: parseInt(i.quantity),
    })),
  };

  console.log('Submit Body:', submitBody);

  const response = await fetch(url + '/rmas', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(submitBody),
  });
  if (!response.ok) throw new Error('Network response was not okay.');
  const json = returnSchema.parse(await response.json());
  console.log('CREATE RMA RESPONSE:', json);

  return json;
}

const returnSchema = z.object({
  data: z.object({
    id: z.string(),
    rma_number: z.number(),
  }),
});
